// src/pages/IntroPage.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SessionModal from '../components/SessionModal';
import { checkSessionHistory } from '../api/chatApi';
import '../styles/style.css';
import robotIcon from '../assets/robot_icon.png';
import logoIcon from '../assets/monstarx_logo.png';

const IntroPage: React.FC = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleGetStarted = async () => {
    setLoading(true);
    try {
      const existingToken = localStorage.getItem('monstarx_session_token');
     // console.log('IntroPage: Existing token check:', existingToken ? 'found' : 'not found');
      
      if (existingToken) {
        const history = await checkSessionHistory(existingToken);
      //  console.log('IntroPage: History check:', history.data.messages.length > 0 ? 'has messages' : 'no messages');
        
        if (history.data.messages.length > 0) {
          setShowModal(true);
          return;
        } else {
          localStorage.removeItem('monstarx_session_token');
        }
      }
      navigate('/chat');
    } catch (error) {
      console.error('IntroPage: Error checking session:', error);
      localStorage.removeItem('monstarx_session_token');
      navigate('/chat');
    } finally {
      setLoading(false);
    }
  };

  const handleContinueSession = () => {
   // console.log('IntroPage: Continuing session from intro');
    localStorage.setItem('continuing_from_intro', 'true');
   // console.log('IntroPage: Set continuing_from_intro flag');
    setShowModal(false);
    navigate('/chat');
  };

  const handleStartNew = () => {
  //  console.log('IntroPage: Starting new session');
    localStorage.removeItem('monstarx_session_token');
    localStorage.removeItem('continuing_from_intro');
    localStorage.removeItem('continue_previous_session');
    setShowModal(false);
    navigate('/chat');
  };

  return (
    <div className="main-container">
      {/* Logo */}
      <img src={logoIcon} alt="MonstarX Logo" className="logo" />

      {/* Background Decorations */}
      <div className="background-decorations">
        <div className="ellipse role-pm"></div>
        <div className="star role-designer"></div>
        <div className="rectangle role-qa"></div>
        <div className="polygon role-dev"></div>
      </div>

      {/* Robot Icon */}
      <img src={robotIcon} alt="Robot Icon" className="robot-icon" />

      {/* Description Text */}
      <div className="description-text">
        Hello! I'm Jimmy, your dedicated product solution tailor. I'm excited to help you bring your product idea to life!
      </div>

      {/* How It Works Section */}
      <div className="how-it-works">
        How it works?
        <div className="underline"></div>
      </div>

      {/* Grid Container */}
      <div className="grid-container">
        <div className="grid-item">
          <span className="step-label">STEP 1</span>
          <h3 className="title">Vision Discovery</h3>
          <p className="grid-description">I'll ask questions to fully understand your goals and expectations.</p>
        </div>
        <div className="grid-item">
          <span className="step-label">STEP 2</span>
          <h3 className="title">Development Blueprint</h3>
          <p className="grid-description">With a clear vision, I'll create a roadmap for your development journey.</p>
        </div>
      </div>

      {/* Get Started Button */}
      <button 
        className="get-started-btn" 
        onClick={handleGetStarted}
        disabled={loading}
      >
        {loading ? 'Loading...' : 'Get Started'}
      </button>

      {showModal && (
        <SessionModal
          onContinue={handleContinueSession}
          onStartNew={handleStartNew}
          onClose={() => setShowModal(false)}
          showCloseButton={true}
        />
      )}
    </div>
  );
};

export default IntroPage;