import React from 'react';
import '../styles/SideMenu.css';
import { FaTimes } from 'react-icons/fa';
import robotIcon from '../assets/robot_icon.png';

interface SideMenuProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmitClose: () => void;
    chatSummary: string[];
    loading: boolean;
    isSubmitting: boolean;
}

const SideMenu: React.FC<SideMenuProps> = ({ isOpen, onClose, onSubmitClose, chatSummary, loading, isSubmitting }) => {
    return (
        <div className={`side-menu ${isOpen ? 'open' : ''}`}>
            <div className="side-menu-header">
                <h2 className="summary-title">
                    <img src={robotIcon} alt="Robot" className="robot-icon" />
                    <span>Summary</span>
                </h2>
                <button className="close-button" onClick={onClose}>
                    <FaTimes />
                </button>
            </div>
            <div className="side-menu-content">
                {loading ? (
                    <div className="loader-container">
                        <div className="loader"></div>
                        <p className="loading-text">Summarising for you...</p>
                    </div>
                ) : (
                    <div className="summary-content">
                        {chatSummary.length > 0 ? (
                            chatSummary.map((item, index) => (
                                <div key={index} className="summary-item">
                                    {item}
                                </div>
                            ))
                        ) : (
                            <div className="summary-item">No summary available.</div>
                        )}
                    </div>
                )}
            </div>
            <button 
                className="submit-close-button" 
                onClick={onSubmitClose} 
                disabled={isSubmitting}
            >
                {isSubmitting ? 'Submitting...' : 'Submit & Close'}
            </button>
        </div>
    );
};

export default SideMenu;