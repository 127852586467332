import React, { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';

interface ConfirmationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  confirmText: string;
  cancelText: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onConfirm,
  onCancel,
  title,
  confirmText,
  cancelText,
}) => {
  const [submitted, setSubmitted] = useState(false);

  const handleConfirm = () => {
    onConfirm();
    setSubmitted(true);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        {submitted ? (
          <>
            <FaCheckCircle className="success-icon" />
            <h2>Thanks for your submission, our team will get back to you.</h2>
            <button className="confirm-button" onClick={onCancel}>Start New</button>
          </>
        ) : (
          <>
            <h2>{title}</h2>
            <div className="modal-buttons">
              <button className="confirm-button" onClick={handleConfirm}>{confirmText}</button>
              <button className="cancel-button" onClick={onCancel}>{cancelText}</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ConfirmationModal; 