// src/App.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import IntroPage from './pages/IntroPage';
import ChatPage from './pages/ChatPage';
import AdminLogin from './pages/panel-bot/login';
import Dashboard from './pages/panel-bot/dashboard';
import ProtectedRoute from './components/ProtectedRoute';

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<IntroPage />} />
      <Route path="/chat" element={<ChatPage />} />
      <Route path="/panel-bot/login" element={<AdminLogin />} />
      <Route 
        path="/panel-bot/dashboard" 
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        } 
      />
    </Routes>
  );
};

export default App;