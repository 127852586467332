// src/api/chatApi.ts
import axios from 'axios';
import { SessionResponse } from '../models/ChatSession';
import { ChatMessage } from '../models/ChatMessage';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': API_KEY,
  },
  withCredentials: true, 
});

export const createSession = async (): Promise<SessionResponse> => {
  try {
    const response = await api.get('/api/session/create');
    return response.data;
  } catch (error: any) {
    console.error('Error in createSession API:', error.response?.data || error.message);
    throw error;
  }
};

export const sendMessage = async (session_token: string, content: string) => {
  try {
    const response = await api.post('/api/chat/communicate', {
      session_token,
      role: 'user',
      content
    });
    return response.data;
  } catch (error: any) {
    console.error('Error in sendMessage API:', error.response?.data || error.message);
    throw error;
  }
};

export const checkSessionHistory = async (sessionToken: string) => {
  try {
    const response = await api.get(`/api/chat/history?session_token=${sessionToken}`);
    return response.data;
  } catch (error) {
    console.error('Error checking session history:', error);
    throw error;
  }
};

export const closeChat = async (sessionToken: string) => {
  try {
    const response = await api.post('/api/chat/close', {
      session_token: sessionToken,
    });
    return response.data;
  } catch (error: any) {
    console.error('Error in closeChat API:', error.response?.data || error.message);
    throw error;
  }
};

export const getChatSummary = async (sessionToken: string) => {
  try {
      const response = await api.post('/api/chat/summary', {
          session_token: sessionToken,
      });
      return response.data; // Return the response data
  } catch (error: any) {
      console.error('Error retrieving chat summary:', error.response?.data || error.message);
      throw error; // Rethrow the error for handling in the calling function
  }
};