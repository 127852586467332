import React from 'react';
import '../styles/Notification.css'; // Create a CSS file for styling

interface NotificationProps {
    message: string;
    onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({ message, onClose }) => {
    return (
        <div className="notification">
            <span>{message}</span>
            <button className="close-button" onClick={onClose}>X</button>
        </div>
    );
};

export default Notification;