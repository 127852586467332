// src/components/ChatMessage.tsx
import React from 'react';
import robotIcon from '../assets/robot_icon.png';
import { MessageRole } from '../models/ChatMessage';
import ReactMarkdown from 'react-markdown';

interface ChatMessageProps {
  role: MessageRole;
  content: string;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ role, content }) => {
  const displayRole = role === 'You' ? 'user' : role;
  
  return (
    <div className={`message-wrapper ${displayRole}`}>
      {displayRole === 'assistant' && (
        <div className="message-avatar">
          <img src={robotIcon} alt="Bot" className="avatar-icon" />
        </div>
      )}
      <div className="message-content">
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
    </div>
  );
};

export default ChatMessage;