import React from 'react';
import '../styles/SessionModal.css';

interface SessionModalProps {
  onContinue: () => void;
  onStartNew: () => void;
  onClose?: () => void;
  showCloseButton?: boolean;
}

const SessionModal: React.FC<SessionModalProps> = ({ 
  onContinue, 
  onStartNew, 
  onClose,
  showCloseButton = false 
}) => {
  return (
    <div className="session-modal-overlay">
      <div className="session-modal-popup">
        {showCloseButton && (
          <button className="session-modal-close" onClick={onClose}>
            ×
          </button>
        )}
        <div className="session-modal-icon">
          <i className="fas fa-history"></i>
        </div>
        <div className="session-modal-header">
          Continue Previous Chat?
        </div>
        <div className="session-modal-message">
          We found an existing conversation. Would you like to continue where you left off?
        </div>
        <div className="session-modal-buttons">
          <button 
            className="session-modal-button secondary" 
            onClick={onStartNew}
          >
            Start Fresh
          </button>
          <button 
            className="session-modal-button primary" 
            onClick={onContinue}
          >
            Continue Chat
          </button>
        </div>
      </div>
    </div>
  );
};

export default SessionModal; 