import React from 'react';
import ReactMarkdown from 'react-markdown';
import robotIcon from '../assets/robot_icon.png';
import userIcon from '../assets/user_icon.png';

interface Message {
  role: string;
  content: string;
  timestamp?: string;
}

interface ChatHistoryProps {
  session: {
    sessionToken: string;
    messages: Message[];
    lastUpdated: string;
  };
  onClose: () => void;
}

const ChatHistory: React.FC<ChatHistoryProps> = ({ session, onClose }) => {
  const displayMessages = session.messages.slice(1);

  return (
    <div className="chat-history-overlay">
      <div className="chat-history-sidebar">
        <div className="chat-history-header">
          <h2>Chat History</h2>
          <button className="close-btn" onClick={onClose}>&times;</button>
        </div>

        <div className="chat-history-messages">
          {displayMessages.map((message, index) => (
            <div 
              key={index} 
              className={`message-wrapper ${message.role}`}
            >
              <div className="message-avatar">
                <img 
                  src={message.role === 'assistant' ? robotIcon : userIcon} 
                  alt={message.role} 
                  className="avatar-icon" 
                />
              </div>
              <div className="message-content">
                <ReactMarkdown>{message.content}</ReactMarkdown>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChatHistory;