import { v4 as uuidv4 } from 'uuid';

interface LoginCredentials {
    username: string;
    password: string;
  }
  
  interface AdminUser {
    username: string;
    token: string;
  }
  
  interface LoginResponse {
    success: boolean;
    message?: string;
    data?: {
      sessionToken: string;
    };
  }
  
  const generateBrowserFingerprint = (): string => {
    // Combine multiple browser characteristics
    const fingerprint = {
      userAgent: navigator.userAgent,
      language: navigator.language,
      platform: navigator.platform,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      uniqueId: uuidv4() // Generate a unique ID for this browser session
    };
    return btoa(JSON.stringify(fingerprint));
  };
  
  export const validateLogin = async (credentials: LoginCredentials): Promise<LoginResponse> => {
    try {
      const adminUsersStr = process.env.REACT_APP_ADMIN_USERS || '[]';
      const adminUsers = JSON.parse(adminUsersStr);
  
      const user = adminUsers.find(
        (u: AdminUser) => u.username === credentials.username && u.token === credentials.password
      );
  
      if (user) {
        const browserFingerprint = generateBrowserFingerprint();
        
        // Generate a session token with timestamp and fingerprint
        const sessionToken = btoa(JSON.stringify({
          username: user.username,
          timestamp: new Date().getTime(),
          expiresAt: new Date().getTime() + (30 * 60 * 1000), // 30 minutes expiry
          fingerprint: browserFingerprint
        }));
  
        // Store fingerprint separately
        sessionStorage.setItem('browserFingerprint', browserFingerprint);
  
        return { 
          success: true, 
          data: { sessionToken }
        };
      }
  
      return { success: false, message: 'Invalid credentials' };
    } catch (error) {
      console.error('Login validation error:', error);
      return { success: false, message: 'An error occurred during login' };
    }
  };
  
  export const checkAuthToken = (): boolean => {
    try {
      const sessionToken = sessionStorage.getItem('adminSession');
      const storedFingerprint = sessionStorage.getItem('browserFingerprint');
      
      if (!sessionToken || !storedFingerprint) return false;
  
      const decoded = JSON.parse(atob(sessionToken));
      
      // Check if session is expired
      if (decoded.expiresAt < new Date().getTime()) {
        clearSession();
        return false;
      }
  
      // Validate fingerprint matches
      if (decoded.fingerprint !== storedFingerprint) {
        clearSession();
        return false;
      }
  
      // Validate username exists in admin users
      const adminUsersStr = process.env.REACT_APP_ADMIN_USERS || '[]';
      const adminUsers = JSON.parse(adminUsersStr);
      const isValidUser = adminUsers.some((u: AdminUser) => u.username === decoded.username);
  
      if (!isValidUser) {
        clearSession();
        return false;
      }
  
      return true;
    } catch {
      clearSession();
      return false;
    }
  };
  
  export const clearSession = () => {
    sessionStorage.removeItem('adminSession');
    sessionStorage.removeItem('browserFingerprint');
  };