import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': API_KEY,
  },
  withCredentials: true, 
});

interface Session {
  sessionToken: string;
  messages: any[];
  lastUpdated: string;
  userDetails: {
    userAgent: string;
  };
}

interface SessionHistoryResponse {
  status: string;
  message: string;
  data: {
    sessions: Session[];
    hasMore: boolean;
    nextPageToken: string;
  };
}

export const fetchSessionHistory = async (
  pageSize: number = 50, 
  lastDoc?: string
): Promise<SessionHistoryResponse> => {
  try {
    const url = lastDoc 
      ? `/api/session/history?page_size=${pageSize}&last_doc=${lastDoc}`
      : `/api/session/history?page_size=${pageSize}`;
    
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    console.error('Error fetching session history:', error.response?.data || error.message);
    throw error;
  }
};

export const searchSessions = async (
  searchTerm: string,
  pageSize: number = 50
): Promise<SessionHistoryResponse> => {
  try {
    const url = `/api/session/history?page_size=${pageSize}&search=${searchTerm}`;
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    console.error('Error searching sessions:', error.response?.data || error.message);
    throw error;
  }
};